import React from 'react'
import { Link } from 'gatsby'

import SEO from '../components/seo'

import Header from '../components/header';
import SocialMedia from '../components/socialmedia';
import '../styles/index.scss';
import TypedInfo from '../components/TypedInfo';

const IndexPage = () => (
  <div className="landingbg">
    <SEO title="Home" keywords={[`eileen lin`, `application`, `react`, `front-end`, `engineer`, `developer`, `ad tech`, `advertising`]} />
    <Header />
    <div className="landing-container">
      <div className="landing-section">
        <h1 className="animated fadeInDown">Hello, I'm Eileen</h1>
        <div>
          <TypedInfo strings={[
    	      'I am a: <strong>web developer</strong>^2000',
            'I am a: <strong>data analyst</strong>',
            'I am an: <strong>avid planner</strong>'
            ]}
          />
        </div>
        <SocialMedia />
        <div className="landing-btn">
          <Link className="button button--primary" to="/work">View Work</Link>
        </div>
        
      </div>
    </div>
    
  </div>

);

export default IndexPage
